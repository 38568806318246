import React, { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react";
import {
  Box,
  Typography,
  Grid,
  useTheme,
  Theme,
  useMediaQuery,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { useAppStore } from "@/stores";
import { formatNumber } from "@/helpers/formatter";

import CoinPriceLoading from "./CoinPriceLoading";
import PriceXrp from "./PriceXrp";
import PriceUsd from "./PriceUsd";

interface CoinPricesProps {}

const CoinPrices: React.FC<CoinPricesProps> = observer(() => {
  const theme = useTheme();
  const {
    dexStore: {
      coins,
      baseCoin,
      price,
      baseCoinMeta,
      isMetaDataLoading,
      isPriceLoading,
      rateUsdXrp,
    },
  } = useAppStore();
  const [marketcap, setMarketcap] = useState<number | undefined>();
  const [volume, setVolume] = useState<number>();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));

  useEffect(() => {
    const coin = coins.find(
      (coin) =>
        coin.ticker === baseCoin?.ticker && coin.issuer === baseCoin?.issuer
    );

    // console.log(coin, "coin");

    // console.log(
    //   "marketcap",
    //   baseCoin?.marketcap,
    //   Number(baseCoin?.marketcap_fl ?? 0),
    //   Number(baseCoin?.marketcap_fl)
    //     ? Number(baseCoin?.marketcap_fl)
    //     : coin?.marketcap
    // );

    // console.log(
    //   "volume",
    //   Number(baseCoin?.vol24h_fl),
    //   coin?.vol24hxrp,
    //   parseFloat(baseCoinMeta?.volume.toString().replace(/,/g, "") ?? ""),
    //   Number(baseCoin?.vol24h_fl)
    //     ? Number(baseCoin?.vol24h_fl)
    //     : parseFloat(baseCoinMeta?.volume.toString().replace(/,/g, "") ?? "")
    // );

    // console.log(price, "price");

    setMarketcap(
      Number(baseCoin?.marketcap_fl && baseCoin?.marketcap_fl / rateUsdXrp) ||
        coin?.marketcap
    );
    // console.log(marketcap, "marketcap");
    setVolume(
      Number(baseCoin?.vol24h_fl)
        ? Number(baseCoin?.vol24h_fl)
        : Number(coin?.vol24hxrp)
    );
  }, [
    coins,
    baseCoin?.marketcap,
    baseCoin?.marketcap_fl,
    baseCoin?.vol24h_fl,
    baseCoin?.vol24hxrp,
    baseCoinMeta,
    price,
    baseCoin?.issuer,
    baseCoin?.ticker,
    rateUsdXrp,
  ]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                className="boxCard"
                py={2.5}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  height: {
                    xs: "60px",
                    sm: "72px",
                  },
                }}
              >
                <Typography
                  sx={(theme) => ({
                    fontSize: "12px",
                    fontWeight: 400,
                    lineHeight: "12px",
                    color: theme.palette.text.grayBorder,
                  })}
                >
                  Price
                </Typography>
                {/* <Typography color="text.main" fontSize="12px"> */}
                {!isMetaDataLoading && !isPriceLoading ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <PriceXrp price={price} fontSize="12" />
                    <PriceUsd price={price * rateUsdXrp} fontSize="12" />
                  </Box>
                ) : (
                  <CoinPriceLoading />
                )}
                {/* </Typography> */}

                {!isPriceLoading ? (
                  <Typography
                    color={
                      baseCoinMeta && price > baseCoinMeta.dailyCloseYesterday
                        ? (theme.palette as any).text.green
                        : (theme.palette as any).text.red
                    }
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      // height: "10px", // default height for xs screens
                      // [theme.breakpoints.up("sm")]: {
                      //   height: "32px", // height for sm and above screens
                      // },
                      borderRadius: "8px",
                    })}
                    fontSize="12px"
                  >
                    {baseCoinMeta &&
                    price > baseCoinMeta.dailyCloseYesterday ? (
                      <ArrowDropUpIcon
                        sx={{
                          color: theme.palette.text.green,
                        }}
                      />
                    ) : (
                      <ArrowDropDownIcon
                        sx={{
                          color: theme.palette.text.red,
                        }}
                      />
                    )}
                    {!isMetaDataLoading &&
                      !isPriceLoading &&
                      baseCoinMeta &&
                      baseCoinMeta?.dailyCloseYesterday > 0 &&
                      formatNumber(
                        Math.abs(
                          ((price - baseCoinMeta?.dailyCloseYesterday) /
                            baseCoinMeta?.dailyCloseYesterday) *
                            100
                        ).toFixed(2)
                      )}
                    {"%"}
                  </Typography>
                ) : null}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                className="boxCard"
                py={2.5}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  height: {
                    xs: "60px",
                    sm: "72px",
                  },
                }}
              >
                <Typography sx={titleSx}>24H Volume</Typography>
                {!isMetaDataLoading &&
                !isPriceLoading &&
                volume !== undefined ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <PriceXrp price={volume} fontSize="12" />
                    <PriceUsd price={volume * rateUsdXrp} fontSize="12" />
                  </Box>
                ) : (
                  <CoinPriceLoading />
                )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={6} xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                className="boxCard"
                py={2.5}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  height: {
                    xs: "60px",
                    sm: "72px",
                  },
                }}
              >
                <Typography sx={titleSx}>Market Cap</Typography>

                {!isMetaDataLoading && rateUsdXrp && marketcap !== undefined ? (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    {marketcap && (
                      <>
                        <PriceXrp price={marketcap} fontSize="12" />
                        <PriceUsd
                          price={marketcap * rateUsdXrp}
                          fontSize="12"
                        />
                      </>
                    )}
                  </Box>
                ) : (
                  <CoinPriceLoading />
                )}
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box
                className="boxCard"
                py={2.5}
                px={2}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{
                  height: {
                    xs: "60px",
                    sm: "72px",
                  },
                }}
              >
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  justifyContent={"space-between"}
                  height={{ sm: "auto", md: "100%" }}
                  width="50%"
                  borderRight={`1px solid ${"#3B474F"}`}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    mb={{ xs: "8px", md: "auto" }}
                  >
                    <img src="/assets/images/arrowRightUp.svg" alt="Trade" />
                    <Typography sx={titleGreenSx}>24H High</Typography>
                  </Box>

                  {!isMetaDataLoading && !isPriceLoading ? (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <PriceXrp
                        price={parseFloat(
                          (baseCoinMeta?.high?.toString() ?? "").replace(
                            /,/g,
                            ""
                          )
                        )}
                        fontSize="12"
                      />
                      <PriceUsd
                        price={
                          parseFloat(
                            (baseCoinMeta?.high?.toString() ?? "").replace(
                              /,/g,
                              ""
                            )
                          ) * rateUsdXrp
                        }
                        fontSize="10"
                      />
                    </Box>
                  ) : (
                    <CoinPriceLoading />
                  )}
                </Box>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-end"
                  justifyContent="space-between"
                  height={{ sm: "auto", md: "100%" }}
                  width="50%"
                  pl={2}
                >
                  <Box
                    display="flex"
                    flexDirection="row"
                    alignItems="flex-start"
                    mb="8px"
                  >
                    <img src="/assets/images/arrowRightDown.svg" alt="Trade" />
                    <Typography sx={titleRedSx}>24H Low</Typography>
                  </Box>

                  {!isMetaDataLoading && !isPriceLoading ? (
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <PriceXrp
                        price={parseFloat(
                          (baseCoinMeta?.low?.toString() ?? "").replace(
                            /,/g,
                            ""
                          )
                        )}
                        fontSize="12"
                      />
                      <PriceUsd
                        price={
                          parseFloat(
                            (baseCoinMeta?.low?.toString() ?? "").replace(
                              /,/g,
                              ""
                            )
                          ) * rateUsdXrp
                        }
                        fontSize="10"
                      />
                    </Box>
                  ) : (
                    <CoinPriceLoading />
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});

const titleSx = (theme: Theme) => ({
  fontSize: "12px",
  fontWeight: 400,
  lineHeight: "12px",
  color: theme.palette.text.grayBorder,
});

const titleGreenSx = (theme: Theme) => ({
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10px",
  color: theme.palette.text.green,
  ml: "8px",
});

const titleRedSx = (theme: Theme) => ({
  fontSize: "10px",
  fontWeight: 400,
  lineHeight: "10px",
  color: theme.palette.text.negativeFigure,
  ml: "8px",
});

export default CoinPrices;
