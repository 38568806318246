import { makeAutoObservable, runInAction } from "mobx";
import {
  Client,
  Response,
  AccountLinesResponse,
  AccountInfoResponse,
  BookOffersResponse,
  PathFindRequest,
  PathFindResponse,
  TransactionStream,
  AccountOffersRequest,
  AccountOffersResponse,
  AccountTxRequest,
  AccountTxResponse,
} from "xrpl";
import * as Sentry from "@sentry/react";

import { ICurrency, offerModeType } from "@/types/ICurrency";

import { offerMode, defaultCoins } from "./data/dexData";

import RootStore from "./index";

const wss = ["wss://s1.ripple.com/"]; //["wss://xrplcluster.com/"];
// const wss = ["wss://s.altnet.rippletest.net:51233"]; //["wss://xrplcluster.com/"];
const genesisXRPAccount = "rHb9CJAWyB4rj91VRWn96DkukG4bwdtyTh";

export default class XRPLStore {
  public rootStore: RootStore;
  private client: Client;
  private offerSubscribe: { [key: string]: string } = {};

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true });
    this.rootStore = rootStore;
    this.client = new Client(wss[this.getServerIndex(wss)]);

    this.client.on("transaction", (tx: TransactionStream) => {
      this.rootStore.accountStore.transactionSubscribeUpdate(tx);
      this.rootStore.dexStore.transactionSubscribeUpdate(tx);
    });

    this.init();
  }

  async init() {
    await this.connectClient();
  }

  private async connectClient() {
    try {
      await this.client.connect();
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async disconnect() {
    if (!this.client || !this.client.isConnected()) return;

    try {
      await this.client.disconnect();
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  getServerIndex(servers: string[]) {
    return Math.floor(Math.random() * servers.length);
  }

  async request(data: any) {
    if (!this.client.isConnected()) {
      await this.connectClient();
    }

    try {
      const result: Response = await this.client.request(data);

      return result;
    } catch (err: any) {
      Sentry.captureException(err);
      return err.data;
    }
  }

  async account_info(account: string) {
    if (!this.client.isConnected()) {
      await this.client.connect();
    }

    try {
      const response = await this.client.request({
        command: "account_info",
        account: account,
        ledger_index: "current",
      });

      return response;
    } catch (err) {
      Sentry.captureException(err);
    }
  }

  async getAltBalances(account: string) {
    const response: AccountLinesResponse = await this.request({
      command: "account_lines",
      account: account,
      strict: false,
    });

    const lines = response?.result?.lines ?? [];

    const balances = lines.map((line) => ({
      balance: Number(line.balance),
      currency: line.currency,
      issuer: line.account,
    }));

    return balances;
  }

  async getXrpBalance(account: string) {
    const response: AccountInfoResponse = await this.request({
      command: "account_info",
      account: account,
      ledger_index: "current",
    });

    const account_data = response?.result?.account_data;
    console.log(account_data, "account_data");

    const baseReserve = 1 * 1_000_000; // Base reserve in XRP
    const incrementalReserve = 0.2; // Reserve per owned object in XRP
    const ownerCount = account_data.OwnerCount;

    // Calculate the total reserve
    const totalReserve = ownerCount * incrementalReserve * 1_000_000;

    // Calculate the available balance
    const availableBalance =
      (Number(account_data.Balance) - (totalReserve + baseReserve)) / 1_000_000;

    return { balance: availableBalance, currency: "XRP" };
  }

  async getXrpPrice(
    base: ICurrency | "XRP",
    quote: ICurrency | "XRP",
    limit: number = 10
  ) {
    return {};
  }

  async getOffer(
    baseCoin: ICurrency,
    quoteCoin: ICurrency,
    mode: offerModeType = offerMode.sell,
    limit: number = 10
  ) {
    const baseParam =
      baseCoin.currencyHex === "XRP"
        ? { currency: "XRP" }
        : { currency: baseCoin.currencyHex, issuer: baseCoin.issuer };
    const quoteParam =
      quoteCoin.currencyHex === "XRP"
        ? { currency: "XRP" }
        : { currency: quoteCoin.currencyHex, issuer: quoteCoin.issuer };

    const takerPays = mode === offerMode.sell ? quoteParam : baseParam;
    const takerGets = mode === offerMode.sell ? baseParam : quoteParam;

    const response: BookOffersResponse = await this.request({
      command: "book_offers",
      taker_gets: takerGets,
      taker_pays: takerPays,
      limit: limit,
    });

    const subscribeId = `Subscribe to ${takerPays.currency}/${takerGets.currency} order book`;

    if (this.offerSubscribe[mode] !== subscribeId) {
      await this.request({
        id: subscribeId,
        command: "subscribe",
        books: [
          {
            taker_pays: takerPays,
            taker_gets: takerGets,
            snapshot: true,
          },
        ],
      });
    }

    runInAction(() => {
      this.offerSubscribe[mode] = subscribeId;
    });

    let offers = response?.result?.offers ?? [];
    return offers;
  }

  async getPathFind({
    sendCoin = defaultCoins.xrp,
    receiveCoin = defaultCoins.xrp,
    sourceAmount,
    destinationAmount = -1,
    account = genesisXRPAccount,
  }: {
    sendCoin?: ICurrency;
    receiveCoin?: ICurrency;
    sourceAmount?: number;
    destinationAmount?: number;
    account?: string;
  }) {
    const _destinationAmount =
      receiveCoin.currencyHex === "XRP"
        ? destinationAmount.toString()
        : {
            value: destinationAmount.toString(),
            currency: receiveCoin.currencyHex,
            issuer: receiveCoin.issuer,
          };

    const _sourceAccount =
      sendCoin.currencyHex === "XRP" ? account : sendCoin.issuer;

    const requestBody: PathFindRequest = {
      command: "path_find",
      subcommand: "create",
      source_account: _sourceAccount,
      destination_account: account,
      destination_amount: _destinationAmount,
      // ledger_index: "current",
    };

    if (sourceAmount) {
      const _sourceAmount =
        sendCoin.currencyHex === "XRP"
          ? sourceAmount.toString()
          : {
              value: sourceAmount.toString(),
              currency: sendCoin.currencyHex,
              issuer: sendCoin.issuer,
            };

      requestBody.send_max = _sourceAmount;
    }

    const response: PathFindResponse = await this.request(requestBody);

    return response;
  }

  async accountSubscribe(account: string) {
    if (!account) return;

    await this.request({
      id: "Subscribe to user account transactions",
      command: "subscribe",
      accounts: [account],
    });
  }

  async getAccountOffers(account: string) {
    if (!account) return;

    const request: AccountOffersRequest = {
      command: "account_offers",
      account: account,
      binary: false,
      forward: false,
      limit: 100,
      ledger_index_max: -1,
      ledger_index_min: -1,
    };

    const response: AccountOffersResponse = await this.request(request);

    return response;
  }

  async getAccountTx(account: string, tx_type: string = "OfferCreate") {
    if (!account) return;

    const request: AccountTxRequest = {
      command: "account_tx",
      tx_type: "OfferCreate",
      account: account,
      binary: false,
      forward: false,
      limit: 1000,
      ledger_index_max: -1,
      ledger_index_min: -1,
      api_version: 2,
    };

    const response: AccountTxResponse = await this.request(request);
    return response;
  }
}
